import React from 'react';
import {
    Box, Button, HStack, Text,
} from '@chakra-ui/react';

import { useHistory, useParams } from 'react-router-dom';
import { GroupEntriesTable } from 'app/components/tables/group-entries';
import { useStore } from 'app/state/store';


const ContributionsView = () => {
    const { group_id } = useParams<any>();
    const { group } = useStore();
    const history = useHistory();

    return (
        <Box w="100%" py={[2, 4]} px={[4, 6]}>
            <HStack spacing="2" mb="4">
                <Button size="sm" colorScheme="calypso" variant="outline" onClick={history.goBack}>
                    Back
                </Button>
                <Text fontWeight="black" fontSize="lg">{group.name}: {group_id}</Text>
            </HStack>
            <GroupEntriesTable group_id={group_id} filters={{ uuid: group_id }} />
        </Box>
    );
};

export default ContributionsView;
