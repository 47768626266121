import { Badge } from "@chakra-ui/react";
import React from "react";


export const getStatusColor = (status: string) => {
    return {
        "pending": "blue",
        "complete": "teal",
        "rejected": "red",
        "approved": "pigment-indigo",
        "initiated": "gray",
        "success": "green",
        "failed": "red",
    }[status];
}

export const LoanStatus = ({ status }: any) => {
    if (!status) return null;

    return <div>
        <Badge colorScheme={getStatusColor(status.toLowerCase())}>{status}</Badge>
    </div>
}
