import dayjs from 'dayjs';
import * as React from 'react'
import { Card, CardContent, Property } from './cards';


export const PaymentDetails = ({ payment }: any) => {

    return (
        <Card>
            <CardContent>
                <Property label="UUID" value={payment.uuid} />
                <Property label="Phone" value={payment.phone} />
                <Property label="Amount" value={Number(payment.amount).toLocaleString()} />
                <Property label="Narration" value={payment.narration} />
                <Property label="Reason" value={payment.reason} />
                <Property label="Date" value={dayjs(payment.date_created).format('DD MMM, YYYY HH:mm:ss')} />
            </CardContent>
        </Card>
    )
}
