import { makePaymentApi } from "factory";
import { useState } from "react";
import { handleError, handleSuccess } from ".";


export const usePayments = () => {
    const paymentsApi = makePaymentApi();

    const [item, setItem] = useState<any>(null);
    const [list, setList] = useState<any>(null);
    const [loading, setLoading] = useState(false);


    const getPayments = async ({ page, filters }: any) => {
        setLoading(true);
        const { data, error } = await paymentsApi.getPayments(page, filters);
        handleError(error);
        setList(data);
        setLoading(false);
        return data;
    };

    const createPayment = async (values: any) => {
        setLoading(true);
        const { data, error } = await paymentsApi.createPayment(values);
        handleError(error);
        if (data) {
            handleSuccess("The payment has been successfully registered");
        }
        setItem(data);
        setLoading(false);
        return { data, error };
    }

    return { 
        item, 
        list, 
        getPayments,
        createPayment,
        loading
    };
}
