import { Avatar, Select } from '@chakra-ui/react';
import { Feature } from 'app/components/controls';
import { useProfiles } from 'app/hooks/backend';
import { useSession } from 'app/hooks/backend/session';
import dayjs from 'dayjs';
import React from 'react';


export const SideBar = () => {
  const { user } = useSession();
  const { profiles, currentProfile, setCurrentProfile } = useProfiles();

  const onChangeProfile = async (e: any) => {
    const profile = profiles.find((p: any) => p.uuid === e.target.value);
    await setCurrentProfile(profile);
  };

  return (
    <aside className="z-20 flex-shrink-0 hidden w-60 overflow-y-auto bg-[#F3F6F9] md:block">
      <div className="flex flex-col items-start">
        <div className="mt-2 bg-[#F3F6F9]">
          <p className="text-2xl text-blue-gray-800 font-semibold">Mayicard</p>
        </div>
        <div className="mt-4 flex space-x-2 items-center">
          {/* <img className="hidden h-24 w-24 rounded-full sm:block object-cover border-4 border-bg-[#F3F6F9]"
            src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" /> */}
          <Avatar name={user?.name} h={12} w={12} src={user?.photo} />
          <div className="">
            <p className="font-semibold text-base text-gray-800 mt-2">{user?.name}</p>
            <p className="text-calypso-700 font-semibold text-sm">{user?.entity.name}</p>
            <Feature>
              <Select
                variant='filled'
                disabled={true}
                defaultValue={currentProfile.uuid}
                size="sm"
                onChange={onChangeProfile}>
                {profiles.map((profile: any) => (
                  <option key={profile.id} value={profile.uuid}>{profile.entity.name}</option>
                ))}
              </Select>
            </Feature>
          </div>
        </div>

        <div className="flex flex-col space-y-6 mt-16">
          <p className="font-bold text-base text-calypso-600">Profile</p>
          <span>
            <p className="text-blue-gray-500 text-sm italic">Client ID:</p>
            <p className="text-calypso-900">{user?.uuid}</p>
          </span>
          <span>
            <p className="text-blue-gray-500 text-sm italic">Email:</p>
            <p className="text-calypso-900">{user?.email}</p>
          </span>
          <span>
            <p className="text-blue-gray-500 text-sm italic">Phone No:</p>
            <p className="text-calypso-900">{user?.phone}</p>
          </span>
          <span>
            <p className="text-blue-gray-500 text-sm italic">Joined:</p>
            <p className="text-calypso-900">{dayjs(user?.date_created).format("DD MMMM, YYYY")}</p>
          </span>
        </div>


        <div className="mt-16">
          {/* <p className="font-semibold text-base text-calypso-600 mb-8">Loan Limit</p>
          <div className="h-24 w-48 rounded-t-full border-4 border-b-0 border-calypso-600 flex items-center justify-center">
            <p className="text-calypso-600 text-md font-bold mt-6">50,000 UGX</p>
          </div>
          <div className="flex w-48 justify-between text-sm font-semibold">
            <p>0</p>
            <p>50 000</p>
          </div> */}
        </div>

      </div>
    </aside>
  )
}
