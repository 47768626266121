const primary = { light: 'calypso.600', dark: 'calypso.400' };

const theme = {
  primary,
  link: primary,
};

const Config = {
  FLAGSMITH_KEY: 'WS5Z7zBWdh3GtBKVUJ6ofR',
  THEME: theme,
};

export default Config;
