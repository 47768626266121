import { IPaymentRepository, PaymentCreateParams } from 'domain/repositories/payment';
import { IDomainResponse } from '.';

export class PaymentsInteractor {
    constructor(private readonly repo: IPaymentRepository) { }

    async createPayment(params: PaymentCreateParams): Promise<IDomainResponse> {
        try {
            const data = await this.repo.createPayment(params);
            return { data, error: null };
        }
        catch (error) {
            return { data: null, error };
        }
    }

    async getPayments(page: number, filters: any): Promise<IDomainResponse> {
        try {
          const data = await this.repo.getPayments(page, filters);
          return { data, error: null };
        }
        catch (error) {
          return { data: null, error };
        }
      }
}
