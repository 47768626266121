import { HttpClient } from "data/protocols/http";

import { ApiResult } from "./models/api-result";
import { getQueryParams, receiveResponse } from "./utils";
import { UserProfile, GetUsersParams, IUserRepository } from "domain/repositories/user";

export class UserApi implements IUserRepository {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<ApiResult>
  ) { }

  async getUser(username: string): Promise<any> {
    const queryParams = getQueryParams({ username });
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryParams}`,
      method: "get",
    });

    return receiveResponse(httpResponse);
  }

  async getUsers(params: GetUsersParams): Promise<any> {
    const queryParams = getQueryParams(params);
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryParams}`,
      method: "get",
    });

    return receiveResponse(httpResponse);
  }

  async getProfile(phone: string) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?phone=${phone}`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }

  async getProfileById(uuid: string) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${uuid}`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }

  async createProfile(params: UserProfile) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/`,
      method: "post",
      body: params
    })
    return receiveResponse(httpResponse);
  }

  async updateProfile(id: number, params: UserProfile) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/`,
      method: "put",
      body: params
    })
    return receiveResponse(httpResponse);
  }
}
