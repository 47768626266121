export const API = {
  development: 'https://core.test.fouronefinancial.com/',
  test: 'https://core.test.fouronefinancial.com/',
  production: 'https://core.fouronefinancial.com/',
};

const GATEWAY = {
  development: 'https://gateway.test.fouronefinancial.com/',
  test: 'https://gateway.test.fouronefinancial.com/',
  production: 'https://gateway.fouronefinancial.com/',
}


export const Environment = {
  API_URL: process.env.REACT_APP_API_URL || API[process.env.NODE_ENV],
  GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL || GATEWAY[process.env.NODE_ENV],
  API_TOKEN: process.env.REACT_APP_API_TOKEN,
  FLW_KEY: process.env.REACT_APP_FLW_KEY || 'FLWPUBK_TEST-SANDBOXDEMOKEY-X'
};
