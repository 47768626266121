import React from 'react';
import { Stat, StatLabel, StatNumber, StatGroup, useBreakpointValue } from '@chakra-ui/react';
import { Feature } from '../controls';

export const TableStats = ({ name = 'Records', count, pages, page }: any) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <StatGroup
      bgColor="calypso.100"
      display="flex"
      flexWrap="wrap"
      mb="4"
      border="1px solid"
      borderColor="gray.100"
      rounded="md"
      p="2">
      <Stat>
        <StatLabel color="gray.500" fontSize="xs">
          {name}
        </StatLabel>
        <StatNumber fontSize="md" color="calypso.700" textAlign={isMobile ? 'center' : 'inherit'}>
          {count.toLocaleString()}
        </StatNumber>
      </Stat>
      <Feature on={pages > 1}>
        <Stat>
          <StatLabel color="gray.500" fontSize="xs">
            Pages
          </StatLabel>
          <StatNumber fontSize="md" color="calypso.700">
            {pages.toLocaleString()}
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel color="gray.500" fontSize="xs">
            Current Page
          </StatLabel>
          <StatNumber fontSize="md" color="calypso.700">
            <span>{page.toLocaleString()}</span>
          </StatNumber>
        </Stat>
      </Feature>
    </StatGroup>
  );
};
