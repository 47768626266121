import { makeResourceApi } from "factory";
import { useState } from 'react';
import { handleError, handleSuccess } from '.';

export const useResource = (resource: string) => {
  const api = makeResourceApi(resource);

  const [item, setItem] = useState<any>(null);
  const [list, setList] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const getResource = async (id: number | string) => {
    setLoading(true);
    const { data, error } = await api.getResource(id);
    handleError(error);
    setList(data);
    setLoading(false);
    return data;
  };

  const getResources = async ({ page, filters }: any) => {
    setLoading(true);
    const { data, error } = await api.getResources(page, filters);
    handleError(error);
    setList(data);
    setLoading(false);
    return data;
  };

  const createResource = async (values: any) => {
    setLoading(true);
    const { data, error } = await api.createResource(values);
    handleError(error);
    if (data) {
      handleSuccess(data?.msg || 'The resource has been successfully created');
    }
    setItem(data);
    setLoading(false);
    return { data, error };
  };

  const updateResource = async (id: any, params: any) => {
    setLoading(true);
    const { data, error } = await api.updateResource(id, params);
    handleError(error);
    if (data) {
      handleSuccess('The resource has been successfully updated');
    }
    setItem(data);
    setLoading(false);
    return { data, error };
  };

  const deleteResource = async (id: any) => {
    setLoading(true);
    const { data, error } = await api.deleteResource(id);
    handleError(error);
    if (data) {
      handleSuccess('The resource has been deleted');
    }
    setItem(data);
    setLoading(false);
    return { data, error };
  };

  return {
    item,
    list,
    getResource,
    getResources,
    createResource,
    updateResource,
    deleteResource,
    loading,
  };
};

export const useMisc = () => {
  const [loading, setLoading] = useState(false);

  const initiateTask = async ({ name, args }: { name: string; args: string[] }) => {
    const api = makeResourceApi('tasks');
    setLoading(true);
    const { data, error } = await api.createResource({ name, args });
    handleError(error);
    setLoading(false);

    if (data) {
      handleSuccess('The transaction has been initiated successfully');
    }
    return data;
  };

  return {
    initiateTask,
    loading,
  };
};
