import React from 'react';
import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

// export const Balance = (props: any) => {
//   const { getBalances } = useProfiles();

//   const { isLoading, data: results } = useQuery('user-balances', getBalances);
//   const accounts: any[] = results?.data || [];

//   return <>{isLoading ? <Spinner /> : <Box {...props}>{accounts[0].balance}</Box>}</>;
// };

export const DateInfo = ({ date }: any) => (
  <>
    <div className="text-sm leading-5 flex items-center space-x-2">
      <span>{dayjs(date).format('YYYY-MM-DD')}</span>
    </div>
    {/* <div className="text-sm leading-5 text-gray-500">{dayjs(date).format('hh:mm a')}</div> */}
  </>
);