import {
    Box, Table, Thead, Tr, Th, Tbody, Td, Text, IconButton, Button, useBreakpointValue
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../controls/loading';
import Pagination from '../controls/pagination';

import { useResource } from 'app/hooks/backend/resource';
import { Feature } from '../controls';
import { DeleteIcon } from '@chakra-ui/icons';
import { ActionBtn } from '../buttons/action';
import { TableStats } from '../widgets/table-stats';
import { AddRecordButton } from '../buttons/add-button';
import { AddMemberForm } from '../forms/group-member';
import { useGroup } from 'app/hooks/groups';
import { DateInfo } from '../widgets/misc';


export const GroupMembersTable = ({ filters, group }: any) => {
    const per_page = 10;
    const { isGroupAdmin } = useGroup(group);
    const [page, setPage] = useState(1);
    const { getResources, loading, deleteResource } = useResource(`groups/${group.id}/members`);
    const isMobile = useBreakpointValue({ base: true, md: false });

    const { isLoading, data, refetch } = useQuery(
        ['group-members', { page, filters }],
        () => getResources({
            page,
            filters
        }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const deleteMember = async (member_id: any) => {
        await deleteResource(member_id);
        refetch();
    };

    if (isLoading || loading) return <Loading />;

    const list = data?.results;
    const total = data?.total;

    return (
        <>
            <Box mb="4">
                <TableStats name="Members" count={total} pages={data?.pages} page={page} />
                <Feature on={isGroupAdmin}>
                    <AddRecordButton AddForm={<AddMemberForm group_id={group.id} onComplete={refetch} />}>
                        <Button _focus={{ boxShadow: 'none', outline: 'none' }} colorScheme="calypso" size="sm">Add Member</Button>
                    </AddRecordButton>
                </Feature>
            </Box>
            <Box className="block lg:hidden flex flex-col space-y-2 text-sm">
                {list?.map((item: any) =>
                    <Box
                        key={`mobile-${item.id}`}
                        p={1}
                        rounded="md">
                        <Box className="flex justify-between border-b-0">
                            <div className="flex flex-col max-w-1/2">
                                <Text className="font-black">{item?.customer?.name}</Text>
                                <Text fontWeight="medium" className="text-gray-400">{dayjs(item.date_created).format("DD MMM, YYYY hh:mm a")}</Text>
                            </div>
                            <div className="flex flex-col">
                                <span className="font-black text-right">{Number(item.contributions).toLocaleString()}</span>
                            </div>
                        </Box>
                    </Box>
                )}
                <span className="mb-4" />
            </Box>
            <Table hidden={isMobile} variant="simple" border="1px" borderColor="gray.100">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Contributions</Th>
                        <Th>Phone</Th>
                        <Th>Email</Th>
                        <Th>Joined</Th>
                        <Feature on={isGroupAdmin}>
                            <Th>Remove</Th>
                        </Feature>
                    </Tr>
                </Thead>
                <Tbody>
                    {list?.map((item: any) =>
                        <Tr key={item.id}>
                            <Td>{item.customer?.name}</Td>
                            <Td>{Number(item.contributions).toLocaleString()}</Td>
                            <Td>{item.customer?.phone}</Td>
                            <Td>{item.customer?.email}</Td>
                            <Td><DateInfo date={item.date_created} /></Td>
                            <Feature on={isGroupAdmin}>
                                <Td>
                                    <ActionBtn
                                        title={`Remove ${item.customer?.name}`}
                                        onAction={async () => await deleteMember(item?.id)}>
                                        <IconButton
                                            _focus={{ boxShadow: 'none' }}
                                            colorScheme="calypso"
                                            variant="ghost"
                                            size="xs"
                                            icon={<DeleteIcon />}
                                            aria-label="Members"
                                        />
                                    </ActionBtn>
                                </Td>
                            </Feature>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            <Feature on={total > per_page}>
                <Pagination page={page} total={total} limit={10} setPage={setPage} />
            </Feature>
        </>
    )
}
