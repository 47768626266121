import React from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { currentUserState } from "app/state/atoms";

import MainLayout from "app/layouts";
import { AppRoutes } from "../routes";

const PrivateRoute: React.FC<RouteProps> = (props: RouteProps) => {

  const { getCurrentUser } = useRecoilValue(currentUserState);
  const currentProfile = getCurrentUser();

  return currentProfile ? (
    <MainLayout>
      <Route {...props} />
    </MainLayout>
  ) : (
    <Route {...props} component={() => <Redirect to={AppRoutes.LOGIN} />} />
  );
};

export default PrivateRoute;
