import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AppRoutes } from 'app/pages/routes';
import { currentUserState } from 'app/state/atoms';

export const useSession = () => {
  const history = useHistory();
  let location = useLocation();
  const { getCurrentUser } = useRecoilValue(currentUserState);

  const currentProfile = getCurrentUser();

  useEffect(() => {
    if (!currentProfile && location.pathname !== AppRoutes.LOGIN) {
      history.push(AppRoutes.LOGIN);
    }
    // eslint-disable-next-line
  }, [currentProfile, location]);

  return {
    user: currentProfile,
  };
};
