import { makeCookieStorageAdapter } from 'factory/cache';

export const useCookies = () => {
  const cookies = makeCookieStorageAdapter();

  const setCookie = async (
    key: string,
    value: any,
    expiresIn: any = new Date(new Date().getTime() + 60 * 60 * 1000)
  ): Promise<void> => {
    cookies.set(key, value, expiresIn);
  };

  const getCookie = (key: string): any => {
    const result = cookies.get(key);
    return result;
  };

  const removeCookie = (key: string): void => cookies.remove(key);

  return {
    setCookie,
    getCookie,
    removeCookie,
  };
};
