import { HttpClient, HttpRequest, HttpResponse } from 'data/protocols/http';
import { GetStorage } from 'data/protocols/cache';

// export class AuthorizeHttpClientDecorator implements HttpClient {
//   constructor(private readonly getStorage: GetStorage, private readonly httpClient: HttpClient) {}

//   async request(data: HttpRequest): Promise<HttpResponse> {
//     const session = this.getStorage.get('session');
//     console.log(session)
//     if (session?.access_token) {
//       Object.assign(data, {
//         headers: Object.assign(data.headers || {}, {
//           Authorization: `Bearer ${session.access_token}`,  
//         }),
//       });
//     }
//     const httpResponse = await this.httpClient.request(data);
//     return httpResponse;
//   }
// }

export class AuthorizeHttpClientDecorator implements HttpClient {
  constructor(private readonly getStorage: GetStorage, private readonly httpClient: HttpClient) {}

  async request(data: HttpRequest): Promise<HttpResponse> {
    const tokens = this.getStorage.get('session');

    Object.assign(data, {
      headers: Object.assign(data.headers || {}, {
        Authorization: `Bearer ${tokens?.access_token}`,  
      }),
    });
    const httpResponse = await this.httpClient.request(data);
    return httpResponse;
  }
}
