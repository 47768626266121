import { Stack, Input, Button, Text } from "@chakra-ui/react";
import { useResource } from "app/hooks/backend/resource";
import { useSession } from "app/hooks/backend/session";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Label } from "./fields";
import { useFormErrors } from "./utils";


export const AddMemberForm = ({ group_id, onComplete }: any) => {
  const { user } = useSession();
  const { getResources: getCustomer } = useResource('customers');
  const { createResource, loading } = useResource(`groups/${group_id}/members`);
  const [name, setName] = useState('');

  const { handleSubmit, register, errors } = useForm<any>();

  useFormErrors(errors);

  const onSubmit = handleSubmit(async data => {
    const customer_ids = [data.customer_id];

    await createResource({ customer_ids });
    onComplete && onComplete();
  });

  const searchCustomer = async (query: string) => {
    if (query.length < 4) return;

    const { results } = await getCustomer({ filters: { uuid: query, entity_id: user.entity_id } });
    if (results) setName(results[0]?.name);
    else setName('A customer with this ID does not exist');
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing="2" mb="4">
        <div className="space-y-2">
          <Label>Customer ID</Label>
          <Input
            variant="filled"
            type="text"
            name="customer_id"
            focusBorderColor="calypso.500"
            placeholder="0000"
            onInput={(e: any) => searchCustomer(e.target.value)}
            ref={register({ 
              required: true, 
              validate: value => value.length === 4,
              pattern: {
                value: /^[0-9]{4}$/,
                message: "Invalid customer ID"
              }
            })}
          />
        </div>
        <Text hidden={!name} rounded="md" bg="gray.100" px="5" py="2">{name}</Text>
      </Stack>
      <Button type="submit" size="sm" colorScheme="calypso" isLoading={loading}>Add Member</Button>
    </form>
  );
};