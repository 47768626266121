import Axios from 'axios';
import { Environment } from 'env';
import { makeCookieStorageAdapter } from "factory/cache";


const cookies = makeCookieStorageAdapter();
const tokens = cookies.get("session");

const headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${tokens?.access_token}`
}

// if (tokens) {
//     headers['Authorization'] = `Bearer ${tokens?.access_token}`
// }

export const api = Axios.create({
    baseURL: Environment.API_URL,
    headers,
});

export const gateway = Axios.create({
    baseURL: Environment.GATEWAY_URL,
    headers,
});

export default api;
