// Auth usecases
import { IDomainResponse } from ".";
import { IUserRepository, GetUsersParams, UserProfile } from "domain/repositories/user";

export class UsersInteractor {
  constructor(private readonly userRepo: IUserRepository) { }


  async getUser(username: string): Promise<IDomainResponse> {
    return await this.userRepo.getUser(username);
  }

  async getUsers(params: GetUsersParams): Promise<IDomainResponse> {
    return await this.userRepo.getUsers(params);
  }

  async getProfile(phone: string) {
    try {
      const data = await this.userRepo.getProfile(phone);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async getProfileById(uuid: string) {
    try {
      const data = await this.userRepo.getProfileById(uuid);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async createProfile(params: UserProfile) {
    try {
      const data = await this.userRepo.createProfile(params);
      return { data, error: null };
    }
    catch (error) {
      return { data: null, error };
    }
  }

  async updateProfile(id: number, values: UserProfile) {
    try {
      const data = await this.userRepo.updateProfile(id, values);
      return { data, error: null };
    }
    catch (error) {
      return { data: null, error };
    }
  }
}
