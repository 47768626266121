import { useSession } from 'app/hooks/backend/session';
import React from 'react';

type Trait = {
  obj?: any;
  attribute: string;
  value?: any | Array<any>;
};

type Permissions = {
  allowed?: Trait[];
  rejected?: Trait[];
};

type FeatureProps = {
  on?: boolean;
  flag?: string;
  children: React.ReactNode;
  permissions?: Permissions;
};


export function isPermitted(user: any, allowed: Trait[] = [], rejected: Trait[] = []) {
  for (var i = 0; i < allowed?.length; i++) {
    const permission = allowed[i];
    const obj = permission.obj || user;

    if (!obj) return false;

    // if no value is specified, then check if the attribute exists
    if (!permission.value) return obj.hasOwnProperty(permission.attribute);
    // if a value is specified, check if the attribute has the value
    else {
      // if the value is an array, check if the attribute has any of the values
      if (Array.isArray(permission.value)) {
        if (!permission.value.includes(obj[permission.attribute])) return false;
      }
      // if the value is not an array, check if the attribute has the value
      else {
        if (obj[permission.attribute] !== permission.value) return false;
      }
    }
  }

  for (var j = 0; j < rejected?.length; j++) {
    const permission = rejected[j];
    const obj = permission.obj || user;

    if (Array.isArray(permission.value)) {
      // immediately return null if the permission is not fulfilled
      if (permission.value.includes(obj[permission.attribute])) return false;
    } else {
      if (obj[permission.attribute] === permission.value) return false;
    }
  }

  return true;
}

export const Feature = ({ on, children, permissions }: FeatureProps) => {
  const { user } = useSession();

  if (on) return <>{children}</>;

  if (!permissions) return null;

  if (!isPermitted(user, permissions.allowed, permissions.rejected)) return null;

  return <>{children}</>;
};
