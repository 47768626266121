const brand = {
  colors: {
    // http://sitehero.nl/uicolors/
    flow: {
      100: '#e3eaff',
      200: '#aabfff',
      300: '#7194ff',
      400: '#396aff',
      500: '#003fff',
      600: '#0031c6',
      700: '#00238e',
      800: '#002185',
      900: '#001555',
    },
    azure: {
      50: '#f4f7fb',
      100: '#e7eef7',
      200: '#cadaed',
      300: '#9bbade',
      400: '#6596cb',
      500: '#4279b5',
      600: '#3466a3',
      700: '#284c7c',
      800: '#254267',
      900: '#233957',
    },
    cerulean: {
      50: '#f0f9ff',
      100: '#e0f2fe',
      200: '#bbe7fc',
      300: '#7fd4fa',
      400: '#3bbff5',
      500: '#1098d1',
      600: '#0586c4',
      700: '#066a9e',
      800: '#095a83',
      900: '#0e4b6c',
    },
    calypso: {
      50: '#f0f9fb',
      100: '#d9eff4',
      200: '#b8dfe9',
      300: '#87c7d9',
      400: '#4ea5c2',
      500: '#3389a7',
      600: '#2e7290',
      700: '#2a5c74',
      800: '#2a4d60',
      900: '#274152',
    },
    gothic: {
      50: '#f7fcfd',
      100: '#effafb',
      200: '#deeff4',
      300: '#c5e1e7',
      400: '#8bb6c1',
      500: '#6b9aa6',
      600: '#3e6772',
      700: '#2a535e',
      800: '#163843',
      900: '#082431',
    },
    cumin: {
      50: '#fdf7ef',
      100: '#faedda',
      200: '#f3d8b5',
      300: '#ecbe85',
      400: '#e39954',
      500: '#dc7e33',
      600: '#ce6728',
      700: '#ab5023',
      800: '#934526',
      900: '#6e3720',
    },
    camelot: {
      50: '#fbf4f9',
      100: '#f8ebf3',
      200: '#f2d8e9',
      300: '#e8b9d7',
      400: '#d98dbb',
      500: '#ca6aa1',
      600: '#b54d83',
      700: '#9b3b6b',
      800: '#8d3861',
      900: '#6d2e4c',
    },
    wedgewood: {
      50: '#f2f9f9',
      100: '#ddeff0',
      200: '#bfe0e2',
      300: '#92cace',
      400: '#5faab1',
      500: '#438e96',
      600: '#3b757f',
      700: '#356169',
      800: '#325158',
      900: '#2d464c',
    },
    indigo: {
      100: '#ebf4ff',
      200: '#c3dafe',
      300: '#a3bffa',
      400: '#7f9cf5',
      500: '#667eea',
      600: '#5a67d8',
      700: '#4c51bf',
      800: '#434190',
      900: '#3c366b',
    },
    'valencia': {
      '50': '#fcf4f3',
      '100': '#f9e9e6',
      '200': '#f4d7d2',
      '300': '#ecb6ac',
      '400': '#e29183',
      '500': '#d76956',
      '550': '#d1543d',
      '600': '#ba412c',
      '700': '#8c3121',
      '800': '#782a1c',
      '900': '#672418',
    },
    'yellow': {
      '50': '#ffffe8',
      '100': '#ffffb9',
      '200': '#ffff8b',
      '300': '#ffff5d',
      '400': '#ffff2e',
      '500': '#ffff00',
      '600': '#d1d100',
      '700': '#a2a200',
      '800': '#747400',
      '900': '#464600',
    },
    'fuchsia-pink': {
      '50': '#fbf3fa',
      '100': '#f8e8f5',
      '200': '#f1d5ec',
      '250': '#ecc5e5',
      '300': '#e6b2dc',
      '400': '#db8fcd',
      '450': '#d57cc4',
      '500': '#cc61b8',
      '600': '#b1399b',
      '650': '#9a3287',
      '700': '#872c76',
      '800': '#702462',
      '900': '#601f54',
      '1100': '#36112f',
    },
    'santa-fe': {
      '50': '#f9f4f1',
      '100': '#f4ebe6',
      '200': '#ead8cd',
      '300': '#dabca9',
      '400': '#c89b7e',
      '500': '#b47852',
      '600': '#8f5c3d',
      '650': '#815337',
      '700': '#6b452e',
      '800': '#5d3c28',
      '900': '#4b3120',
    },
    'roof-terracotta': {
      '50': '#fdf7f6',
      '100': '#fcf0ee',
      '200': '#f8e1dd',
      '300': '#f2c3ba',
      '400': '#ea9d90',
      '500': '#e27865',
      '600': '#d64329',
      '650': '#b43822',
      '700': '#a5331f',
      '800': '#892b1a',
      '900': '#782517',
    },
    'pigment-indigo': {
      '50': '#fbf5ff',
      '100': '#f6ebff',
      '200': '#f0dbff',
      '300': '#e1b8ff',
      '400': '#d294ff',
      '450': '#ca80ff',
      '500': '#c16bff',
      '550': '#b54dff',
      '600': '#aa33ff',
      '700': '#8300e0',
      '800': '#6e00bd',
      '900': '#5f00a3',
      '1000': '#4b0082',
    },
    'light-blue': {
      '50': '#f0f9ff',
      '100': '#e0f2fe',
      '200': '#bae6fd',
      '300': '#7dd3fc',
      '400': '#38bdf8',
      '500': '#0ea5e9',
      '600': '#0284c7',
      '700': '#0369a1',
      '800': '#075985',
      '900': '#0c4a6e',
    },
    'blue-gray': {
      '50': '#f8fafc',
      '100': '#f1f5f9',
      '200': '#e2e8f0',
      '300': '#cbd5e1',
      '400': '#94a3b8',
      '500': '#64748b',
      '600': '#475569',
      '700': '#334155',
      '800': '#1e293b',
      '900': '#0f172a',
    },
  },
};

export default brand
