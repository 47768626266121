import React from 'react';

export const useFilters = (defaultValues: any = {}) => {
    const [filters, setFilters] = React.useState({...defaultValues});

    const setFilter = (key: string, value: any) => {
        setFilters({
            ...filters,
            [key]: value,
        });
    };

    return {
        filters,
        setFilter,
    };
};