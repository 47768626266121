import * as React from 'react';
import { Box, useColorModeValue as mode } from '@chakra-ui/react';
// import { ColorModeSwitcher } from 'ColorModeSwitcher';

type LayoutProps = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
};

export const AuthLayout = ({ title, children }: LayoutProps) => {
  return (
    <div className="lg:flex">
      <div className="lg:w-2/5 xl:max-w-screen-sm">
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
        <Box
          color={mode('calypso.700', 'calypso.500')}
          bg={mode('calypso.50', 'calypso.50')}
          className="py-4 flex justify-center lg:justify-start lg:px-12">
          <div className="cursor-pointer flex items-center">
            {/* <div className="px-8">
              <img
                className=""
                src="https://quickdime.co/assets/logo.png"
                width="30%"
                alt="logo"
              />
            </div> */}
            <div className="text-xl tracking-wide font-light mr-12">Mayicard</div>
          </div>
        </Box>
        <div className="mt-4 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-8 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-2xl text-calypso-500 font-display font-semibold lg:text-left xl:text-2xl
                    xl:text-bold">
            {title}
          </h2>
          <div className="mt-8">{children}</div>
        </div>
      </div>
      <Box
        color={mode('gray.700', 'calypso.200')}
        bg={mode('calypso.900', 'calypso.700')}
        className="hidden lg:flex items-center justify-center bg-calypso-100 flex-1 h-screen shadow-md">
        <div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer">
          <img className="" src="./images/transfer.svg" width="100%" alt="Quick Dime" />
        </div>
      </Box>
    </div>
  );
};

export function Copyright(): JSX.Element {
  return (
    <footer className="footer mt-auto footer-light">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 small">Copyright &copy; Mayicard</div>
          <div className="col-md-6 text-md-right small">
            <a href="#!">Privacy Policy</a>
            &middot;
            <a href="#!">Terms &amp; Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
