import { Input, InputGroup, InputLeftAddon, Select } from '@chakra-ui/react';
import { Feature } from 'app/components/controls';
import { getPhoneNumber, Regex } from 'app/utils/regex';
import React from 'react';
import { Controller } from 'react-hook-form';

export const PhonenumberInput = ({ control, name, ...props }: any) => {
  return (
    <InputGroup>
      <Feature on={false}>
        <InputLeftAddon>
          <Select variant="unstyled" _focus={{ outline: 'none', boxShadow: 'none' }}>
            {[{ name: '256', value: 256 }].map(menu => {
              return (
                <option key={menu.value} value={menu.value}>
                  {menu.name}
                </option>
              );
            })}
          </Select>
        </InputLeftAddon>
      </Feature>
      <Feature on={true}>
        <InputLeftAddon children="+256" />
      </Feature>
      <Controller
        name={name}
        control={control}
        defaultValue={props.defaultValue}
        rules={{
          required: true,
          pattern: {
            value: Regex.VALID_PHONE_NUMBER,
            message: 'Please enter a valid phone number',
          },
          setValueAs: (value: any) => getPhoneNumber(value),
        }}
        render={({ onChange, value }) => (
          <Input
            variant="outline"
            type="tel"
            placeholder="777001100"
            pl="2"
            value={value}
            onChange={onChange}
            {...props}
          />
        )}
      />
    </InputGroup>
  );
};
