import React, { useState } from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  Text,
  Tooltip,
  SimpleGrid,
  Select
} from '@chakra-ui/react';

import { useSession } from 'app/hooks/backend/session';
import { MakePaymentButton } from 'app/components/widgets/rave-button';
import { PaymentsTable } from 'app/components/tables/payments';
import { WarningIcon } from '@chakra-ui/icons';
import { Feature } from 'app/components/controls';
import { DepositForm } from 'app/components/forms/deposit';
import { GroupsTable } from 'app/components/tables/groups';
import { useProfiles } from 'app/hooks/backend';
import { useQuery } from 'react-query';
import { useFilters } from 'app/hooks/filters';



const TransactModel = ({ customer, tran_type, isOpen, onClose, onComplete }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Transact</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <DepositForm customer={customer} tran_type={tran_type} onComplete={onComplete} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

const Card = ({ children }: any) => <div className="p-4 text-cumin-900 bg-cumin-100 rounded">
  {children}
</div>

const Dashboard = () => {
  const { user } = useSession();
  const { refreshProfile } = useProfiles();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [tranType, setTranType] = useState("topup");
  const { filters, setFilter } = useFilters();

  const { refetch } = useQuery('profile', () => refreshProfile());

  return (
    <Box w="100%" py={[2, 4]} px={[0, 6]}>
      <Tabs variant="unstyled" isLazy>
        <TabList>
          <Tab focus="outline-none" borderBottom="2px" borderBottomColor="transparent" _selected={{ borderBottomColor: "calypso.600", color: "calypso.600", outline: 'none', boxShadow: 'none' }}>
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
            </svg>
            <Text fontSize={["0.85rem", "md"]} className="font-semibold ml-2">Dashboard</Text>
          </Tab>
          <Tab focus="outline-none" borderBottom="2px" borderBottomColor="transparent" _selected={{ borderBottomColor: "calypso.600", color: "calypso.600", outline: 'none', boxShadow: 'none' }}>
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
            <Text fontSize={["0.85rem", "md"]} className="font-semibold ml-2">Transactions</Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div className="grid grid-cols-2 gap-4 md:w-1/3 divide-x divide-gray-50 my-4">
              <button
                className="inline-flex justify-center px-4 py-2 text-sm font-semibold text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-calypso-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-calypso-500"
                onClick={() => {
                  setTranType("topup");
                  onOpen();
                }}>{user?.entity_id === 'E0020' ? 'Invest' : 'Deposit'}</button>
              <Feature>
                <MakePaymentButton />
              </Feature>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:w-full my-8">
              <Card bgColor="gothic.100">
                <p className="text-sm uppercase text-blue-gray-600">{user?.entity_id === 'E0020' ? 'Investments' : 'Deposits'}</p>
                <span className="flex mt-4 space-x-2">
                  <p className="text-3xl">{(user?.account?.balance || 0).toLocaleString()}</p>
                  <sup className="text-xs">UGX</sup>
                </span>
              </Card>
              <Feature permissions={{ allowed: [{ obj: user, attribute: "entity_id", value: "E0020" }], rejected: [] }}>
                <Card>
                  <p className="text-sm uppercase text-blue-gray-600">Shares</p>
                  <span className="flex mt-4 space-x-2">
                    <p className="text-3xl">{(user?.account?.shares || 0).toLocaleString()}</p>
                    <sup className="text-xs">BRICKS</sup>
                  </span>
                </Card>
              </Feature>
              <Feature permissions={{ allowed: [{ obj: user, attribute: "entity_id", value: "E0020" }], rejected: [] }}>
                <Card>
                  <div className="flex justify-between">
                    <p className="text-sm uppercase text-blue-gray-600">Invoices</p>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label={<div className="text-sm text-semibold">This is your outstanding balance for all your house purchases</div>}
                      bg="blue-gray.200"
                      color="black">
                      <WarningIcon />
                    </Tooltip>
                  </div>
                  <span className="flex mt-4 space-x-2">
                    <p className="text-3xl">{(user?.account?.invoices || 0).toLocaleString()}</p>
                    <sup className="text-xs">UGX</sup>
                  </span>
                </Card>
              </Feature>
            </div>
            <Feature on={user?.groups > 0}>
              <GroupsTable isMobile={isMobile} filters={{}} customer_id={user?.uuid} />
            </Feature>
          </TabPanel>
          <TabPanel>
            <SimpleGrid columns={[2, 4]} spacing="2" mb="4">
              <Select size="sm" rounded="md" placeholder='Status' onChange={(e) => setFilter("status", e.target.value)}>
                <option value="SUCCESS">SUCCESS</option>
                <option value="PENDING">PENDING</option>
                <option value="FAILED">FAILED</option>
              </Select>
              <Select size="sm" rounded="md" placeholder='Type' onChange={(e) => setFilter("tran_type", e.target.value)}>
                <option value="topup">Topup</option>
                <option value="interest">Interest</option>
                <option value="withdraw">Withdraw</option>
              </Select>
            </SimpleGrid>
            <PaymentsTable isMobile={isMobile} filters={{ customer_id: user?.uuid, per_page: 10, ...filters }} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <TransactModel
        customer={user}
        isOpen={isOpen}
        onClose={onClose}
        tran_type={tranType}
        data={user}
        onComplete={async () => {
          await refetch();
          onClose();
        }}
      />
    </Box>
  );
};

export default Dashboard;
