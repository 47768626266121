import {
    AlertDialog, AlertDialogOverlay,
    AlertDialogContent, AlertDialogHeader,
    AlertDialogBody, Input, AlertDialogFooter, Button
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { Feature } from "../controls/feature";


export const ActionBtn = ({ children, onAction, title, shouldGetReason, label='Submit' }: any) => {
    const cancelDialogRef = useRef<any>();
    const [reason, setReason] = useState<string>();
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);

    const toggleConfirmDialog = () => setIsOpenConfirmDialog(!isOpenConfirmDialog);
    const onToggleConfirmDialog = (e: any) => {
        e?.stopPropagation();
        toggleConfirmDialog();
    };

    const noReason = shouldGetReason && (!reason || reason?.length < 10);

    const onSubmit = async (e: any) => {
        e?.stopPropagation();
        await onAction();
        toggleConfirmDialog();
    };

    return (
        <>
            <button onClick={onToggleConfirmDialog}>
                {children}
            </button>
            <AlertDialog
                isCentered
                isOpen={isOpenConfirmDialog}
                leastDestructiveRef={cancelDialogRef}
                onClose={onToggleConfirmDialog}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <Feature on={!!title}>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                {title}
                            </AlertDialogHeader>
                        </Feature>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                            <Feature on={shouldGetReason}>
                                <Input
                                    focusBorderColor={noReason ? 'red.400' : ''}
                                    className="mt-4"
                                    placeholder="Enter reason for reversal"
                                    onChange={({ target: { value } }) => setReason(value)} />
                                {noReason &&
                                    <p className="text-sm mt-2 text-red-600">Please add a reason for reversal.</p>
                                }
                            </Feature>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelDialogRef} size="sm" onClick={onToggleConfirmDialog}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" size="sm" onClick={onSubmit} ml={3} disabled={noReason}>
                                {label}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
