/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { createStandaloneToast } from '@chakra-ui/react';
import { makeUserApi } from 'factory/interactors';
import { useCookies } from '../cookies';
import { currentUserState } from 'app/state/atoms';
import { useRecoilValue } from 'recoil';
import LogRocket from 'logrocket';

const toast = createStandaloneToast();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useProfiles = () => {
  const { getCookie, setCookie } = useCookies();
  const userInteractor = makeUserApi();
  const { setCurrentUser, getCurrentUser } = useRecoilValue(currentUserState);

  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  const saveProfiles = async (profiles: any[]) => {
    await setCookie('profiles', profiles);
  };

  const getProfiles = async () => {
    setLoading(true);
    const loggedInUser = getCookie("loggedInUser");
    const { data, error } = await userInteractor.getProfile(loggedInUser);
    if (data) {
      const userProfiles = data?.results;

      setCurrentUser(userProfiles[0]);
      userProfiles.forEach((userProfile: any) => {
        delete userProfile.accounts
      });
      await saveProfiles(userProfiles);

      return userProfiles;
    }
    else {
      handleError(error, "Profile Error");
    }
    setLoading(false);
  };

  const getProfileById = async (uuid: string) => {
    setLoading(true);
    try {
      const { data, error } = await userInteractor.getProfileById(uuid);
      if (error) {
        handleError(error, "Profile Error");
      }

      const userProfile = data?.customer;
      return userProfile;
    } catch (error) {
      handleError(error, "Profile Error");
    }
    finally {
      setLoading(false);
    }
  };

  const setCurrentProfile = async (profile: any) => {
    setCookie("currentProfile", profile.uuid);
    profile &&
      LogRocket.identify(profile.uuid, {
        name: `${profile.first_name} ${profile.last_name}`,
        email: profile.email,
        phone: profile.phone,
        entity_id: profile.entity_id,
        entity: profile.entity?.name,
        env: process.env.NODE_ENV
      });
    setCurrentUser(profile);
  };

  const refreshProfile = async () => {
    const currentProfileId = getCookie("currentProfile");
    setLoading(true);
    const profile = await getProfileById(currentProfileId);
    if (profile) {
      setCurrentProfile(profile);
    }
    setLoading(false);
  };


  const handleError = (error: any, title: string) => {
    if (error) {
      const errMsg: any = error?.message;
      toast({
        title: title,
        description: errMsg,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return {
    error,
    profiles: getCookie("profiles") || [],
    currentProfile: getCurrentUser(),
    setError,
    loading,
    getProfiles,
    getProfileById,
    setCurrentProfile,
    refreshProfile,
  };
};
