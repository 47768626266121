import { IDomainResponse } from ".";
import { IAuthenticationRepository } from "../repositories/auth";

export class AuthInteractor {
  constructor(private readonly authRepo: IAuthenticationRepository) { }

  async login(params: IAuthenticationRepository.LoginParams): Promise<IDomainResponse> {
    try {
      const data = await this.authRepo.login(params);
      return { data, error: null }
    } catch (error) {
      return { data: null, error }
    }
  }
}
