import { useEffect, useState } from "react";
import { useSession } from "./backend/session";

export const useGroup = (group: any = null) => {
    const { user } = useSession();
    const [isGroupAdmin, setIsGroupAdmin] = useState(false);

    useEffect(() => {
        const groupAdmins = group.meta?.preferences?.admins;
        if (groupAdmins) {
            const isAdmin = groupAdmins?.includes(user.uuid);
            setIsGroupAdmin(isAdmin);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isGroupAdmin };
};