import { getCurrentUserAdapter, setCurrentUserAdapter } from "app/adapters";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { currentUserState } from "app/state/atoms";

import PrivateRoute from "./private-route/private-route";
import { Login, Signup, ResetStart, ChangePassword, ChooseEntity } from "./auth";
import Dashboard from "./dashboard";
import { AppRoutes } from "./routes";
import { GroupRoutes } from "./groups";

const Router: React.FC = () => {
  const state = {
    setCurrentUser: setCurrentUserAdapter,
    getCurrentUser: getCurrentUserAdapter,
  };
  return (
    <RecoilRoot initializeState={({ set }) => set(currentUserState, state)}>
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/login" to={AppRoutes.LOGIN} />
          <Route path={AppRoutes.LOGIN} exact component={Login} />
          <Route path={AppRoutes.SIGNUP} exact component={Signup} />
          <Route path={AppRoutes.RESET_PASSWORD} exact component={ResetStart} />
          <Route path={AppRoutes.CHANGE_PASSWORD} exact component={ChangePassword} />
          <Route path={AppRoutes.CHOOSE_ENTITY} exact component={ChooseEntity} />
          <PrivateRoute path={AppRoutes.DASHBOARD} exact component={Dashboard} />
        </Switch>
        <GroupRoutes />
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default Router;
