import create from "zustand"


export const useStore = create<any>(set => ({
    latestLoan: {},
    group: {},
    setGroup: (group: any) => set({ group }),
    setLatestLoan: async (data: any) => {
        set({ latestLoan: data })
    }
}))
