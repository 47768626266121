/* eslint-disable @typescript-eslint/no-explicit-any */
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import { createStandaloneToast } from '@chakra-ui/react';
import { useCookies } from '../cookies';
import { AppRoutes } from 'app/pages/routes';
import api from 'app/services/api';
import { makeRemoteAuthentication } from 'factory';
import { useProfiles } from './profile';

// Create a single supabase client for interacting with your database
const toast = createStandaloneToast();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuth = () => {
  const history = useHistory();
  const { removeCookie, setCookie } = useCookies();
  const authInteractor = makeRemoteAuthentication();
  const { getProfiles, setCurrentProfile } = useProfiles();


  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleError = (error: any, title: string = 'Error') => {
    if (error) {
      const errMsg: any = error?.message || error;
      toast({
        title: title,
        description: errMsg,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  const onSignUp = async ({ email, password }: any) => {
    setLoading(true);

    setLoading(false);
  };

  const onLogin = async ({ username, password }: any) => {
    try {
      setLoading(true);
      const { data: clientResult } = await api.post(
        "/auth/login",
        {
          username,
          password,
        }
      );

      if (clientResult) {
        if (!process.env.REACT_APP_GATEWAY_LOCK) {
          handleError("Unable to determine app authentication", "Internal App Error");
          return;
        };
        if (!process.env.REACT_APP_GATEWAY_KEY) {
          handleError("Unable to determine app authentication", "Internal App Error");
          return;
        };

        const creds = {
          username: process.env.REACT_APP_GATEWAY_LOCK,
          password: process.env.REACT_APP_GATEWAY_KEY,
        };

        const { data } = await authInteractor.login(creds);

        setCookie("session", data);
        setCookie("loggedInUser", username);

        const profiles = await getProfiles();

        if (profiles.length === 1) {
          await setCurrentProfile(profiles[0]);
          history.push(AppRoutes.DASHBOARD);
        }
        else {
          history.push(AppRoutes.CHOOSE_ENTITY);
        }
      } else {
        handleError("Client authentication failed", 'Login failed');
      }

    }
    catch (error: any) {
      handleError(error?.message, 'Login failed');
    }
    finally {
      setLoading(false);
    }
  };

  const onLogout = async () => {
    removeCookie('session');
    history.push(AppRoutes.LOGIN);
  };

  const onResetStart = async ({ email }: any) => {
    setLoading(true);
    toast({
      title: error ? 'An error occurred' : 'Password Reset',
      description: error ? email : 'Check your email for recovery instructions',
      status: error ? 'error' : 'info',
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
    setLoading(false);
  };

  const onResetFinish = async ({ password, access_token }: any) => {
    setLoading(true);
    history.push(AppRoutes.LOGIN);
    toast({
      title: error ? 'An error occurred' : 'Password Changed',
      description: error ? "error?.message" : 'Password reset successfully. Login',
      status: error ? 'error' : 'info',
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
    setLoading(false);
  };

  return {
    onSignUp,
    onLogin,
    onLogout,
    onResetStart,
    onResetFinish,
    error,
    setError,
    loading,
  };
};
