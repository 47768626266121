import React from 'react';
import { Box, Link, useColorModeValue as mode } from '@chakra-ui/react';

import { AuthLayout } from 'app/layouts/auth';
import { LoginForm, SignUpForm, ResetStartForm, ChangePasswordForm, ChooseEntityForm } from 'app/components/forms';
import { useHistory, useLocation } from 'react-router-dom';
import { AppRoutes } from '../routes';
import Config from 'app/services/config';

export function Login(): JSX.Element {
  const { hash } = useLocation<any>();
  const history = useHistory();

  const params = new URLSearchParams(hash.substring(1));

  switch (params.get('type')) {
    case 'recovery' || 'invite':
      history.push(`${AppRoutes.CHANGE_PASSWORD}?${hash.substring(1)}`);
      break;
    default:
      break;
  }

  return (
    <AuthLayout title="Sign in to your account">
      <LoginForm />
      {/* <Box
        className="mt-8 text-sm font-display font-semibold text-center"
        color={mode('gray.700', 'gray.200')}>
        Don't have an account ?{' '}
        <Link href={AppRoutes.SIGNUP} color={mode(Config.THEME.link.light, Config.THEME.link.dark)}>
          Sign up
        </Link>
      </Box> */}
    </AuthLayout>
  );
}

export function Signup(): JSX.Element {
  return (
    <AuthLayout title="Create an account">
      <SignUpForm />
      <Box
        className="mt-8 text-sm font-display font-semibold text-center"
        color={mode('gray.700', 'gray.200')}>
        Have an account already ?{' '}
        <Link href={AppRoutes.LOGIN} color={mode(Config.THEME.link.light, Config.THEME.link.dark)}>
          Log In
        </Link>
      </Box>
    </AuthLayout>
  );
}

export function ResetStart(): JSX.Element {
  return (
    <AuthLayout title="Reset your account">
      <ResetStartForm />
      <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
        <Link href={AppRoutes.LOGIN} color={mode(Config.THEME.link.light, Config.THEME.link.dark)}>
          Log In
        </Link>
      </div>
    </AuthLayout>
  );
}

export function ChangePassword(): JSX.Element {
  const { search } = useLocation<any>();
  const params = new URLSearchParams(search.substring(1));

  return (
    <AuthLayout title="Change your password">
      <ChangePasswordForm access_token={params.get('access_token')} />
      <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
        <Link href={AppRoutes.LOGIN} color={mode(Config.THEME.link.light, Config.THEME.link.dark)}>
          Log In
        </Link>
      </div>
    </AuthLayout>
  );
}

export function ChooseEntity(): JSX.Element {
  
  return (
    <AuthLayout title="Choose Entity">
      <ChooseEntityForm />
      {/* <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
        <Link href={AppRoutes.LOGIN} color={mode(Config.THEME.link.light, Config.THEME.link.dark)}>
          Log In
        </Link>
      </div> */}
    </AuthLayout>
  );
}
