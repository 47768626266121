import {
    Modal, ModalOverlay, ModalContent,
    ModalHeader, ModalCloseButton, ModalBody,
    useDisclosure
} from "@chakra-ui/react";
import React from "react";


interface IAddRecordButtonProps {
    AddForm: React.ReactNode;
    children: React.ReactNode;
    title?: string;
    modalSize?: string;
}

export const AddRecordButton = ({ AddForm, children, title, modalSize='md' }: IAddRecordButtonProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <span onClick={onOpen}>
                {children}
            </span>
            <Modal isOpen={isOpen} size={modalSize} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton onClose={onClose} />
                    <ModalBody mb="4">
                        {AddForm}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};