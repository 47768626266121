import {
  RemoteAuthentication,
  UserApi,
  ReportApi,
  LoansApi,
  PaymentsApi
} from 'data/api';
import {
  AuthInteractor,
  UsersInteractor,
  ReportInteractor,
  LoansInteractor,
  PaymentsInteractor,
} from 'domain/usecases';
import { makeApiUrl, makeAxiosHttpClient } from './http';

import { makeAuthorizedHttpClient } from './decorators';
import { ResourcesApi } from 'data/api/resource';
import { ResourcesInteractor } from 'domain/usecases/resource';

export const makeRemoteAuthentication = (): AuthInteractor =>
  new AuthInteractor(new RemoteAuthentication(makeApiUrl('auth'), makeAxiosHttpClient()));

export const makeUserApi = (): UsersInteractor =>
  new UsersInteractor(new UserApi(makeApiUrl('api/v1/customers'), makeAuthorizedHttpClient()));

export const makeReportsApi = (): ReportInteractor =>
  new ReportInteractor(new ReportApi(makeApiUrl('api/v1/reports'), makeAuthorizedHttpClient()));

export const makeLoanApi = (): LoansInteractor =>
  new LoansInteractor(new LoansApi(makeApiUrl('api/v1/loans'), makeAuthorizedHttpClient()));

export const makePaymentApi = (): PaymentsInteractor =>
  new PaymentsInteractor(new PaymentsApi(makeApiUrl('api/v1/transactions'), makeAuthorizedHttpClient()));

  export const makeResourceApi = (resource: string): ResourcesInteractor =>
  new ResourcesInteractor(new ResourcesApi(makeApiUrl(`api/v1/${resource}`), makeAuthorizedHttpClient()));
