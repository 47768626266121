import {
  Button,
  useColorModeValue as mode,
  Input,
  Stack,
  As,
  ButtonProps,
  OmitCommonProps,
  Box,
  Text,
  VStack,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Label, PasswordField } from './fields';
import { useAuth, useProfiles } from 'app/hooks/backend';
import { useRef, useState } from 'react';
import { useFormErrors } from './utils';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from 'app/pages/routes';
import classnames from "classnames";

interface FormData {
  username: string;
  password: string;
}

const FormButton = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<
      React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
      keyof ButtonProps
    > &
    ButtonProps &
    OmitCommonProps<any, keyof ButtonProps> & { as?: As<any> | undefined }
) => (
  <Button
    {...props}
    type="submit"
    className="w-full"
    bgColor="calypso.800"
    color="white"
    rounded="full"
    _hover={{ bg: 'calypso.700' }}>
    {props.children}
  </Button>
);

export const LoginForm = () => {
  const { onLogin, loading } = useAuth();
  const { handleSubmit, register, errors } = useForm<FormData>();

  useFormErrors(errors);

  const onSubmit = handleSubmit(async data => {
    await onLogin(data);
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing="8">
          <div>
            <Label>Username</Label>
            <Input
              variant="flushed"
              type="string"
              name="username"
              focusBorderColor="calypso.500"
              placeholder="256777004400"
              ref={register}
            />
          </div>
          <PasswordField variant="flushed" focusBorderColor="calypso.500" ref={register} />
          <FormButton isLoading={loading}>Log In</FormButton>
        </Stack>
      </form>
    </>
  );
};

export const SignUpForm = () => {
  const { onSignUp, loading } = useAuth();
  const { handleSubmit, register, errors } = useForm<FormData>();

  const onSubmit = handleSubmit(async data => {
    await onSignUp(data);
  });

  useFormErrors(errors);

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing="8">
          <div>
            <Label>Email Address</Label>
            <Input
              variant="flushed"
              type="email"
              name="email"
              focusBorderColor="calypso.500"
              placeholder="your@email.com"
              ref={register}
            />
          </div>
          <PasswordField variant="flushed" focusBorderColor="calypso.500" ref={register} />
          <FormButton isLoading={loading}>Register</FormButton>
        </Stack>
        <div className="flex mt-6 items-center text-center">
          <hr className="border-gray-300 border-1 w-full rounded-md" />
          <label color={mode('gray.700', 'gray.200')} className="block font-medium text-sm w-full">
            Continue with
          </label>
          <hr className="border-gray-300 border-1 w-full rounded-md" />
        </div>

        <div className="flex mt-7 justify-center w-full">
          <button className="bg-calypso-500 hover:bg-grey text-white py-2 px-8 rounded-full mr-2 inline-flex items-center hover:shadow-inner transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
            <FaFacebook className="mr-2" />
            <span>Facebook</span>
          </button>
          <button className="bg-red-500 hover:bg-grey text-white py-2 px-8 rounded-full ml-2 inline-flex items-center hover:shadow-inner transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
            <FaGoogle className="mr-2" />
            <span>Google</span>
          </button>
        </div>
      </form>
    </>
  );
};

export const ResetStartForm = () => {
  const { onResetStart, loading } = useAuth();
  const { handleSubmit, register, errors } = useForm<FormData>();

  useFormErrors(errors);

  const onSubmit = handleSubmit(async data => {
    await onResetStart(data);
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing="8">
          <div>
            <Label>Email Address</Label>
            <Input
              variant="flushed"
              type="email"
              name="email"
              focusBorderColor="calypso.500"
              placeholder="your@email.com"
              ref={register({ required: true })}
            />
          </div>
          <FormButton isLoading={loading}>Reset Password</FormButton>
        </Stack>
      </form>
    </>
  );
};

interface ChangeFormData {
  password: string;
  passwordConfirm: string;
}

export const ChangePasswordForm = ({ access_token }: any) => {
  const { onResetFinish, loading } = useAuth();
  const { handleSubmit, register, watch, errors } = useForm<ChangeFormData>();

  useFormErrors(errors);

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = handleSubmit(async data => {
    await onResetFinish({ password: data.password, access_token });
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing="8">
          <div>
            <Label>Password</Label>
            <Input
              variant="flushed"
              name="password"
              type="password"
              required
              focusBorderColor="calypso.500"
              placeholder="Enter new password"
              ref={register({
                required: 'You must specify a password',
                minLength: {
                  value: 6,
                  message: 'Password must have at least 6 characters',
                },
              })}
            />
          </div>
          <div>
            <Label>Confirm Password</Label>
            <Input
              variant="flushed"
              name="passwordConfirm"
              type="password"
              focusBorderColor="calypso.500"
              placeholder="Confirm new password"
              required
              ref={register({
                validate: value => value === password.current || 'The passwords do not match',
              })}
            />
          </div>
          <FormButton isLoading={loading}>Change Password</FormButton>
        </Stack>
      </form>
    </>
  );
};

export const ChooseEntityForm = () => {
  const history = useHistory();
  const { setCurrentProfile, profiles } = useProfiles();
  const [chosenProfileId, setProfileId] = useState(profiles[0]?.uuid);

  const chooseProfile = (value: any) => {
    setProfileId(value);
  };

  const handleSubmit = async () => {
    const profile = profiles.find((p: any) => p.uuid === chosenProfileId);
    await setCurrentProfile(profile);
    history.push(AppRoutes.DASHBOARD);
  }

  return (
    <div className="flex flex-col space-y-6">
      <RadioGroup defaultValue={profiles[0]?.uuid} onChange={chooseProfile} w="full">
        <VStack spacing="1" w="full">
          {profiles?.map((item: any) =>
            <Box
              key={item?.uuid}
              borderColor={{ color: mode('gray.200', 'gray.100') }}
              className={classnames('flex items-center p-2 border-2 rounded w-full',
                item?.uuid === chosenProfileId && 'border-calypso-400')}>
              <Radio value={item.uuid} colorScheme="calypso" _focus={{ outline: 'none', boxShadow: 'none' }} />
              <div className="flex ml-6 items-center justify-between w-full">
                <div>
                  <Text fontSize="md" fontWeight="semibold" color="calypso.800">{item?.entity?.name}</Text>
                  <Text fontSize="xs" fontWeight="semibold" color={mode('gray.500', 'gray.400')}>
                    <span>{item.uuid}</span>
                  </Text>
                </div>
                <div>
                  <Text fontSize="sm" color={mode('calypso.500', 'calypso.200')} fontWeight="semibold">UGX {Number(item.account.balance.toFixed()).toLocaleString()}</Text>
                </div>
              </div>
            </Box>
          )}
        </VStack>
      </RadioGroup>
      <Button colorScheme="calypso" onClick={handleSubmit}>Continue</Button>
    </div>
  );
};
