import {
    Button, FormControl, FormLabel, HStack,
    Input, Stack, Switch, Box, Alert, AlertIcon, Select
} from "@chakra-ui/react";
import { useResource } from "app/hooks/backend/resource";
import { getPhoneNumber } from "app/utils/regex";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Feature } from "../controls";
import { PhonenumberInput } from "./fields/phone";
import { useFormErrors } from "./utils";
import { useQuery } from "react-query";
import { NumberInput } from "./fields/input";
import { DistributionTable } from "../tables/groups";



const getTranRequest = (data: any, customer: any, distributions: any[]) => {
    const share_price = 5000;
    let narration = data.narration;
    data.reference =
        data.reference?.length === 0 || !data.reference
            ? String(Math.random().toString(36).substr(2, 9))
            : data.reference;

    let metadata = {};
    if (data?.shares) {
        narration = `Purchase of ${data?.shares} at a unit cost of ${share_price.toLocaleString()}`
        metadata = { ...metadata, shares: data.shares, share_price: share_price };
    }
    if (distributions?.length) {
        metadata = { ...metadata, distributions };
    }
    
    const isTest = process.env.REACT_APP_API_URL === "https://core.test.fouronefinancial.com/" || process.env.NODE_ENV !== "production";

    return {
        ...data,
        customer_id: customer.uuid,
        category: 'WEB',
        pay_type: isTest ? 'cash' : 'momo',
        phone: customer.phone,
        entity_id: customer.entity_id,
        vendor_id: '10001',
        tag_list: customer.tag_list,
        narration,
        meta: { ...metadata },
    };
};

export const DepositForm = ({ customer, tran_type, onComplete }: any) => {
    const { createResource, loading } = useResource("transactions");
    const methods = useForm();
    const { register, errors, control, handleSubmit, getValues, setValue } = methods;
    const [distributions, setDistributions] = useState<any[]>([]);

    useFormErrors(errors);

    const onSubmit = (data: any) => {
        const tranRequest = getTranRequest({ ...data, tran_type }, customer, distributions);

        createResource(tranRequest).then((resp: any) => {
            onComplete();
        }).catch((err: any) => {
            console.log(err);
        });
    };

    const changeNarration = () => {
        const fieldValue = `Purchase of ${getValues('shares')} shares at ${getValues('share_price')}/=`;
        setValue('narration', fieldValue);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                    <HStack spacing={2}>
                        <div className="w-full">
                            <label className="text-blue-gray-900 mb-1 text-sm">Owner ID</label>
                            <Input
                                disabled
                                value={customer?.uuid}
                                type="text"
                                name="owner_id" />
                        </div>
                        <div className="w-full">
                            <label className="text-blue-gray-900 mb-1 text-sm">Owner Name</label>
                            <Input
                                disabled
                                value={customer?.name || customer?.full_name}
                                type="text"
                                name="owner" />
                        </div>
                    </HStack>
                    <ShareDetailsForm customer={customer} changeNarration={changeNarration} />
                    <Stack spacing={2} direction={["column", "row"]}>
                        <div className="w-full">
                            <label className="text-blue-gray-700 mb-1 text-sm">Phone Number</label>
                            <PhonenumberInput defaultValue={getPhoneNumber(customer?.phone)} name="username" control={control} />
                        </div>
                        <>
                            <div className="w-full">
                                <label className="text-blue-gray-700 mb-1 text-sm">Amount</label>
                                {customer.entity_id === 'E0020' && tran_type === 'topup' ? (
                                    <Input
                                        ref={register({ required: true, min: 25000 })}
                                        type="number"
                                        name="amount"
                                        defaultValue={25000}
                                        onChange={({ target: { value } }) => {
                                            const amount = Number(value);
                                            const share_price = getValues('share_price');
                                            setValue('shares', amount / share_price);
                                            changeNarration();
                                        }} />
                                ) : (
                                    <Input
                                        ref={register({ required: true, min: 2000 })}
                                        defaultValue={2000}
                                        type="number"
                                        name="amount" />
                                )}
                            </div>
                        </>
                    </Stack>
                    <Stack>
                        <div className="w-full">
                            <label className="text-blue-gray-900 mb-1 text-sm">Narration</label>
                            <Input ref={register} defaultValue="Deposit" type="text" name="narration" />
                        </div>
                    </Stack>
                </Stack>
                <Feature on={customer.groups > 0}>
                    <GroupEntryForm customer={customer} my="4" setDistributions={setDistributions} />
                </Feature>
                <div className="mt-6 mb-4">
                    <Button
                        disabled={loading}
                        colorScheme={customer.entity_id === 'E0020' ? 'valencia' : 'calypso'}
                        type="submit"
                        width="20%">
                        {customer.entity_id === 'E0020' ? 'Buy Shares' : 'Deposit'}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

const ItemForm = ({ onSave, options }: any) => {
    const { getValues } = useFormContext();
    const [item, setItem] = useState({ uuid: '', strategy: 'flat', amount: '', type: 'group' });


    const onSubmit = async () => {
        const hasEmptyFields = !Object.values(item).some(x => x !== null && x !== '');
        if (hasEmptyFields) {
            alert('Fill in all item fields');
        } else {
            const data = { ...item };
            onSave(data);
        }
    };

    return (
        <Stack spacing="2" mb="4">
            <HStack spacing={2}>
                <Select
                    minW="10rem"
                    placeholder="Select Group"
                    name="uuid"
                    value={item.uuid}
                    onChange={e => setItem({ ...item, uuid: e.target.value })}>
                    {options.map((option: any) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </Select>
                <Select
                    minW="5rem"
                    name="strategy"
                    value={item.strategy}
                    onChange={e => setItem({ ...item, strategy: e.target.value })}>
                    {['flat', 'percentage'].map((option: any) => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </Select>
                <NumberInput
                    customInput={Input}
                    placeholder="Amount"
                    thousandSeparator={true}
                    defaultValue={getValues('amount')}
                    prefix={item.strategy === 'flat' ? 'UGX ' : ''}
                    suffix={item.strategy === 'percentage' ? ' %' : ''}
                    width="25rem"
                    min="0"
                    max={item.strategy === 'flat' ? getValues('amount') : 100}
                    value={item.amount}
                    onChange={(value: any) => setItem({ ...item, amount: value })} />
            </HStack>
            <Button
                onClick={e => {
                    e.stopPropagation();
                    onSubmit();
                }}
                size="sm"
                px="5"
                width="4rem"
                variant="outline"
                colorScheme="calypso">
                Add
            </Button>
        </Stack>
    );
};

const GroupEntryForm = ({ customer, setDistributions, ...props }: any) => {
    const [shouldDistribute, setShouldDistribute] = useState(false);
    const { getResources } = useResource(`customers/${customer.uuid}/groups`);
    const [groups, setGroups] = useState([]);
    const [items, setItems] = useState<any[]>([]);

    useQuery(`customers/${customer.uuid}/groups`,
        () => getResources({}), {
        onSuccess: (data: any) => {
            if (data.results?.length > 0) {
                setGroups(data.results.map((x: any) => ({ label: x.group.name, value: x.group.uuid })));
            }
        },
    });

    useEffect(() => {
        if (shouldDistribute) {
            setDistributions(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);


    const addItem = (item: any) => {
        if (items) {
            const itemsToSave: any = items?.filter(val => val.group_id !== item.group_id);
            setItems([...itemsToSave, item]);
        } else {
            setItems([item]);
        }

    };

    const removeItem = (item: any) => {
        const itemsToSave = items.filter(val => val.group_id !== item.group_id);
        setItems(itemsToSave);
    };



    return (
        <Box {...props} >
            <FormControl display='flex' alignItems='center'>
                <FormLabel fontSize="sm" htmlFor='distribute' mb='0'>
                    Group Transaction?
                </FormLabel>
                <Switch size="sm" id='distribute' onChange={(e) => setShouldDistribute(!shouldDistribute)} />
            </FormControl>
            <Alert colorScheme="gray" fontSize='xs' p="1.5" rounded="sm" my="2">
                <Box display="flex" alignItems="start">
                    <AlertIcon boxSize="16px" />
                    <span>
                        Divide the transaction across several groups while keeping a portion for your personal account.
                    </span>
                </Box>
            </Alert>
            <Feature on={shouldDistribute}>
                <ItemForm options={groups} onSave={addItem} />
                <DistributionTable items={items} onItemDelete={removeItem} />
            </Feature>
        </Box>
    );
};

const ShareDetailsForm = ({ customer, changeNarration }: any) => {
    const { register, getValues, setValue } = useFormContext();

    return (
        <Feature permissions={{ allowed: [{ obj: customer, attribute: 'entity_id', value: 'E0020' }], rejected: [] }}>
            <Stack direction="row" spacing="2">
                <div className="w-full">
                    <label className="text-blue-gray-700 mb-1 text-sm">Shares</label>
                    <Input
                        type="number"
                        name="shares"
                        min="0"
                        step="0.01"
                        ref={register({ required: true, min: 5 })}
                        defaultValue={5}
                        onChange={({ target: { value } }) => {
                            const shares = Number(value);
                            const share_price = getValues('share_price');
                            setValue('amount', shares * share_price);
                            changeNarration();
                        }} />
                </div>
                <div className="w-full">
                    <label className="text-blue-gray-700 mb-1 text-sm">Share Price</label>
                    <Input
                        type="text"
                        disabled={true}
                        ref={register({ required: true, min: 2000 })}
                        defaultValue={5000}
                        name="share_price"
                        onChange={({ target: { value } }) => {
                            const share_price = Number(value);
                            const shares = getValues('shares');
                            setValue('amount', shares * share_price);
                            changeNarration();
                        }} />
                </div>
            </Stack>
        </Feature>
    )
}