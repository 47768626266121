import {
    Box, Table, Thead, Tr, Th, Tbody, Td,
    Button, useDisclosure, Modal, ModalBody,
    ModalCloseButton, ModalContent, ModalHeader,
    ModalOverlay, Text
} from '@chakra-ui/react';
import { usePayments } from 'app/hooks/backend/payments';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../controls/loading';
import { getStatusColor, LoanStatus } from '../controls/loan-status';
import Pagination from '../controls/pagination';
import { capitalize  } from "lodash";

import { PaymentDetails } from '../widgets/payment-detail';


const ViewPaymentModal = ({ isOpen, onClose, payment }: any) => (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>View Details</ModalHeader>
            <ModalCloseButton onClose={onClose} />
            <ModalBody>
                <PaymentDetails payment={payment} />
            </ModalBody>
        </ModalContent>
    </Modal>
);

export const PaymentsTable = ({ isMobile, filters }: any) => {
    const [page, setPage] = useState(1);
    const { getPayments, loading } = usePayments();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [selectedPayment, setSelectedPayment] = useState(null);

    const { isLoading, data } = useQuery(
        ['payments', { page, filters }],
        () => getPayments({
            page,
            filters
        }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const openViewPaymentModel = (item: any) => {
        setSelectedPayment(item);
        onOpen();
    }

    if (isLoading || loading) return <Loading />;

    const list = data?.results;
    const total = data?.total;

    return (
        <>
            <Box className="block lg:hidden flex flex-col space-y-2 text-sm">
                {list?.map((item: any) =>
                    <Box
                        key={`mobile-${item.id}`}
                        p={2}
                        // borderLeftColor={`${getStatusColor(item.status.toLowerCase())}.500`}
                        borderWidth="1px"
                        rounded="md"
                        bg={`${getStatusColor(item.status.toLowerCase())}.50`} 
                        onClick={() => openViewPaymentModel(item)}>
                        <Box className="flex justify-between border-b-0">
                            <div className="flex flex-col max-w-1/2">
                                <Text className="font-black">{capitalize(item.tran_type)}</Text>
                                <Text fontWeight="medium" className="text-gray-400">{dayjs(item.date_created).format("DD MMM, YYYY hh:mm a")}</Text>
                            </div>
                            <div className="flex flex-col">
                                <span className="font-black text-right">{Number(item.amount).toLocaleString()}</span>
                                <span className="font-black text-gray-600 text-right text-sm flex flex-end">
                                    <LoanStatus status={item.status} />
                                </span>
                            </div>
                        </Box>
                        <Text mt="1" textDecoration="underline" fontSize="0.75rem">Details</Text>
                    </Box>
                )}
                <span className="mb-4" />
            </Box>
            <Table hidden={isMobile} variant="simple">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Amount</Th>
                        <Th>Type</Th>
                        <Th>Status</Th>
                        <Th>Phone</Th>
                        <Th>Date</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {list?.map((item: any) =>
                        <Tr key={item.id}>
                            <Td>{item.id}</Td>
                            <Td>{Number(item.amount).toLocaleString()}</Td>
                            <Td>{item.tran_type}</Td>
                            <Td><LoanStatus status={item.status} /></Td>
                            <Td>{item.phone}</Td>
                            <Td>{dayjs(item.date_created).format("YYYY-MM-DD")}</Td>
                            <Td>
                                <Button onClick={() => openViewPaymentModel(item)} size="xs" colorScheme="calypso">View</Button>
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            <Pagination page={page} total={total} limit={10} setPage={setPage} />
            <ViewPaymentModal isOpen={isOpen} onClose={onClose} payment={selectedPayment} />
        </>
    )
}
