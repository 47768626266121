import React, { useState, Fragment } from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { Dialog, Transition } from '@headlessui/react'
import { useSession } from 'app/hooks/backend/session';
// import { usePayments } from 'app/hooks/backend/payments';
import { FaTimesCircle } from 'react-icons/fa';


type Customer = {
    id: number;
    email: string;
    full_name: string;
    uuid?: string;
    phone: string;
};

type PayButtonProps = {
    customer: Customer;
    amount: number;
    onComplete: Function;
}

export const PayRaveButton = ({ customer, amount, onComplete }: PayButtonProps) => {
    // const { createPayment } = usePayments();
    const envCheck = process.env.NODE_ENV === 'production' ? '' : '-test';

    const config = {
        public_key: process.env.REACT_APP_FLW_KEY || 'FLWPUBK_TEST-SANDBOXDEMOKEY-X',
        tx_ref: `dime${envCheck}-${Date.now()}`.toString(),
        amount: amount,
        currency: 'UGX',
        payment_options: 'card,mobilemoney',
        customer: {
            email: customer.email,
            phonenumber: customer.phone,
            name: customer.full_name,
        },
        customizations: {
            title: 'Mayicard',
            description: 'Pay or Invest',
            logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
        meta: {
            customer_id: customer.uuid,
        }
    };

    const handleFlutterPayment = useFlutterwave(config);

    const handleRaveResponse = (response: any) => {

        const payment = {
            "uuid": response.tx_ref,
            "amount": response.amount,
            "narration": "Payment",
            "user": customer?.id,
            "account": response.customer.phone_number,
            "status": response.status === 'successful' ? 'success' : response.status,
            "status_id": response.transaction_id,
            "reason": response?.status === 'successful' ? response?.reason : (response?.reason || 'payment failed'),
            "created_by": customer?.id,
            "meta": { "rave_response": response }
        };
        console.log(payment);
    }

    const openFlutterwaveModal = () => {
        handleFlutterPayment({
            callback: (response) => {
                handleRaveResponse(response);
                closePaymentModal(); // this will close the modal programmatically
                onComplete();
            },
            onClose: () => { },
        });
    }

    return (
        <>
            <button
                className="inline-flex justify-center px-4 py-2 font-semibold text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
                onClick={openFlutterwaveModal}>Pay</button>
        </>
    )
}

export function MakePaymentButton() {
    const { user } = useSession();
    let [isOpen, setIsOpen] = useState(false);
    const [amount, setAmount] = useState<number>(500);

    const toggleDialog = () => setIsOpen(!isOpen);

    const customer = {
        ...user,
        id: user?.id,
        full_name: user?.name,
        email: user?.email,
        phone: user?.phone,
    }

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <>
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={openModal}
                    className="inline-flex justify-center w-full px-4 py-2 text-sm font-semibold text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
                >
                    Make Payment
                </button>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center bg-opacity-60 bg-black">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="div"
                                    className="flex items-center text-lg justify-between font-semibold leading-6 text-gray-900"
                                >
                                    Make Payment of {amount.toLocaleString()}
                                    <button className="focus:outline-none " onClick={closeModal}>
                                        <FaTimesCircle />
                                    </button>
                                </Dialog.Title>
                                <div className="flex py-4 space-x-4 mt-2 w-full items-center">
                                    <input className="p-2 bg-gray-100 rounded border w-full" placeholder={amount.toString()} onChange={(e) => setAmount(Number(e.target.value))} />
                                    <PayRaveButton customer={customer} amount={amount} onComplete={toggleDialog} />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
