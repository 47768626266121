import { makeCookieStorageAdapter } from 'factory/cache';
import { Session } from 'domain/models/user';

export const setCurrentUserAdapter = (session: Session): void => {
  makeCookieStorageAdapter().set('user', session);
};

export const getCurrentUserAdapter = (): Session => {
  return makeCookieStorageAdapter().get('user');
};

