import React from "react";
import { Router } from "react-router-dom";

import App from "./App";
import history from "app/services/history";

const Root = () => {
  return (
    <Router history={history}>
      <App />
    </Router>
  );
};

export default Root;