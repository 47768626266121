import {
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  Link,
  useColorModeValue as mode 
} from '@chakra-ui/react';
import { AppRoutes } from 'app/pages/routes';
import Config from 'app/services/config';
import * as React from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { Label } from './label';

export const PasswordField = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const mergeRef = useMergeRefs(inputRef, ref);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <FormControl id="password">
      <div className="flex justify-between items-center">
        <Label>Password</Label>
        <div>
          <Link
            href={AppRoutes.RESET_PASSWORD}
            color={mode(Config.THEME.link.light, Config.THEME.link.dark)}
            className="text-xs"
            fontWeight="bold">
            Forgot Password?
          </Link>
        </div>
      </div>
      <InputGroup>
        <InputRightElement>
          <IconButton
            bg="transparent !important"
            variant="ghost"
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          ref={mergeRef}
          name={props?.name ? props.name : 'password'}
          type={isOpen ? 'text' : 'password'}
          autoComplete="current-password"
          placeholder="Enter your password"
          required
          {...props}
        />
      </InputGroup>
    </FormControl>
  );
});

PasswordField.displayName = 'PasswordField';
