import { Box, Button, FormControl, FormLabel, Input, Select, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFormErrors } from './utils';
import dayjs from 'dayjs';
import cuid from 'cuid';
import { useSession } from 'app/hooks/backend/session';
import { useLoans } from 'app/hooks/backend/loans';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)


interface FormData {
    amount: number;
    narration: string;
    period: string;
}

const periods = [
    { key: "1_DAY", name: "1 day", rate: 2, days: 1 },
    { key: "3_DAY", name: "3 days", rate: 4, days: 3 },
    { key: "1_WEEK", name: "1 Week", rate: 6, days: 7 },
    { key: "2_WEEKS", name: "2 Weeks", rate: 8, days: 14 },
    { key: "4_WEEK", name: "4 Weeks", rate: 10, days: 28 },
    { key: "6_WEEK", name: "6 Weeks", rate: 15, days: 42 }
]

export const CreateLoanForm = ({ onComplete }: any) => {
    const { profile } = useSession();
    const { createLoan, loading } = useLoans();
    const [details, setDetails] = useState({ rate: 0, interest: 0, next_date: new Date().toLocaleDateString(), days: 0 });
    const { handleSubmit, register, errors, getValues } = useForm<FormData>();

    useFormErrors(errors);

    const onSubmit = handleSubmit(async values => {
        const period = periods.find(p => p.key === values.period);

        const data = {
            uuid: `dime-${cuid.slug()}`,
            amount: values.amount,
            narration: values.narration,
            phone: profile.phone,
            meta: { period },
            user: profile.id,
        }
        await createLoan(data);
        onComplete && await onComplete();
    });

    const handlePeriodChange = (e: { target: { value: any; }; }) => {
        const period = periods.find(p => p.key === e.target.value);
        const amount = getValues("amount");

        if (period) {
            const interest = amount * (period.rate / 100);
            setDetails({ ...details, interest, rate: period.rate, days: period.days });
        }
    }

    const handleAmountChange = (e: { target: { value: string; }; }) => {
        const selectedPeriod = getValues("period");
        const period = periods.find(p => p.key === selectedPeriod);
        const amount = Number(e.target.value);

        if (period) {
            const interest = amount * (period.rate / 100);
            setDetails({ ...details, interest, rate: period.rate, days: period.days });
        }
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <Stack spacing="6" mb="6">
                    <FormControl>
                        <FormLabel>Amount</FormLabel>
                        <Input name="amount" type="number"
                            onChange={handleAmountChange}
                            required
                            ref={register({
                                min: { value: 5000, message: "Amount cannot be less than 5000" },
                                max: { value: 50000, message: "Amount cannot be greater than 50000" }
                            })} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Purpose</FormLabel>
                        <Input name="narration" type="text" required ref={register} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Period</FormLabel>
                        <Select name="period" type="text" required ref={register} onChange={handlePeriodChange}>
                            {periods.map(period => <option key={period.key} value={period.key}>{period.name}</option>)}
                        </Select>
                    </FormControl>
                    <div>
                        <Box className="flex flex-col mx-0">
                            <p className="text-gray-500">Your total payment will be</p>
                            <span className="flex my-8">
                                <sup>UGX</sup>
                                <h4 className="font-black text-xl md:text-4xl">
                                    {(details.interest + Number(getValues("amount"))).toLocaleString()}
                                </h4>
                            </span>
                            <div className="flex space-x-8 lg:space-x-16">
                                <span>
                                    <p className="text-gray-500 mb-2">Rate</p>
                                    <p className="font-black text-lg md:font-semibold lg:text-2xl">{details.rate}%</p>
                                </span>
                                <span>
                                    <p className="text-gray-500 mb-2">Due Date</p>
                                    <p className="font-black text-lg md:font-semibold lg:text-2xl">{dayjs().add(details.days, 'day').format("DD MMM, YYYY")}</p>
                                </span>
                            </div>
                        </Box>
                    </div>
                    <Button type="submit" colorScheme="calypso" size="md" fontSize="md" disabled={loading}>
                        Submit
                    </Button>
                </Stack>
            </form>
        </>
    );
};
