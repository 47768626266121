import { HttpClient } from "data/protocols/http";

import { ApiResult } from "./models/api-result";
import { getQueryParams, receiveResponse } from "./utils";
import { IPaymentRepository, PaymentCreateParams } from "domain/repositories/payment";

export class PaymentsApi implements IPaymentRepository {
    constructor(
        private readonly url: string,
        private readonly httpClient: HttpClient<ApiResult>
    ) { }

    async createPayment(params: PaymentCreateParams) {
        const httpResponse = await this.httpClient.request({
            // api/payments/
            url: `${this.url}`,
            method: "post",
            body: params
        })
        return receiveResponse(httpResponse);
    }

    async getPayments(page: number, filters: any) {
        const httpResponse = await this.httpClient.request({
            url: `${this.url}?${getQueryParams({ page, ...filters })}`,
            method: "get"
        })
        return receiveResponse(httpResponse);
    }
}
