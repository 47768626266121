import { EditIcon } from '@chakra-ui/icons';
import {
    Table, Thead, Tr, Th, Tbody, Td,
    Text, Stack, HStack, SimpleGrid, IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Link
} from '@chakra-ui/react';
import { useResource } from 'app/hooks/backend/resource';
import { useGroup } from 'app/hooks/groups';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FaEye, FaTrash } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Feature } from '../controls';
import { Loading } from '../controls/loading';
import Pagination from '../controls/pagination';
import { Card, CardContent } from '../widgets/cards';
import { GroupMembersTable } from './group-members';
import { Link as RouterLink } from 'react-router-dom';
import { AppRoutes } from 'app/pages/routes';
import { useStore } from 'app/state/store';


export const GroupCard = ({ group, contributions }: any) => {
    const { setGroup } = useStore();
    const { onClose, isOpen, onOpen } = useDisclosure();
    const { isGroupAdmin } = useGroup(group);

    return (
        <>
            <Card bg="calypso.100">
                <CardContent px="4" py="4">
                    <Stack spacing="0">
                        <Text fontSize="xl" fontWeight="semibold" color="calypso.800">{group.name}</Text>
                        <HStack spacing="1" color="gray.600">
                            <Text fontSize="sm">{group.uuid}</Text>
                            <i>.</i>
                            <HStack spacing={1}>
                                <Text fontSize="sm">
                                    {Number(group?.members?.length).toLocaleString()}
                                </Text>
                                <Text fontSize="sm" opacity="0.8">
                                    members
                                </Text>
                                <Feature on={isGroupAdmin}>
                                    <IconButton
                                        _focus={{ boxShadow: 'none' }}
                                        aria-label="View members"
                                        colorScheme="calypso"
                                        variant="ghost"
                                        size="xs"
                                        icon={<FaEye />}
                                        onClick={onOpen} />
                                </Feature>
                            </HStack>
                            <i>.</i>
                            <HStack spacing={1}>
                                <Text fontSize="sm">
                                    {Number(group?.account?.balance).toLocaleString()}
                                </Text>
                            </HStack>
                        </HStack>
                    </Stack>
                    <Stack spacing="2">
                        <HStack>
                            <Text fontSize="3xl">{Number(contributions || 0).toLocaleString()}</Text>
                            <sup>UGX</sup>
                        </HStack>
                        <Link onClick={() => setGroup(group)} as={RouterLink} to={`${AppRoutes.GROUP_CONTRIBUTIONS}/${group.uuid}`}>
                            <Text fontSize="sm">View contributions</Text>
                        </Link>
                    </Stack>
                </CardContent>
            </Card>
            <Modal isOpen={isOpen} size="full" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{group?.name}</ModalHeader>
                    <ModalCloseButton onClose={onClose} />
                    <ModalBody mb="4">
                        <GroupMembersTable group={group} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export const GroupsTable = ({ isMobile, filters, customer_id }: any) => {
    const per_page = 9
    const [page, setPage] = useState(1);
    const url = 'customers/' + customer_id + '/groups';
    const { getResources, loading } = useResource(url);

    const { isLoading, data } = useQuery(
        [url, { page, filters }],
        () => getResources({
            page,
            per_page,
            filters
        }),
        {
            refetchOnWindowFocus: false,
        }
    );

    if (isLoading || loading) return <Loading />;

    const list = data?.results;
    const total = data?.total;

    function getSum(total: any, item: any) {
        return total + Math.round(item.contributions);
    }

    return (
        <>
            <Feature on={list?.length > 1}>
                <HStack spacing="2" mb="4" bg="calypso.50" rounded="md" p="2">
                    <Text fontWeight="normal" fontSize="lg">Total Contributions:</Text>
                    <Text fontWeight="black" fontSize="lg">{list?.reduce(getSum, 0).toLocaleString()}</Text>
                </HStack>
            </Feature>
            <SimpleGrid columns={isMobile ? 1 : 3} spacing={4}>
                {list?.map((item: any) => (
                    <GroupCard key={item.id} group={item.group} contributions={item.contributions} />
                ))}
            </SimpleGrid>
            <Feature>
                <Table hidden={isMobile} variant="simple">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Code</Th>
                            <Th>Name</Th>
                            <Th>Amount</Th>
                            <Th>Date</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list?.map((item: any) =>
                            <Tr key={item.id}>
                                <Td>{item.id}</Td>
                                <Td>{item.group.uuid}</Td>
                                <Td>{item.group.name}</Td>
                                <Td>{Number(item.group?.account?.balance).toLocaleString()}</Td>
                                <Td>{dayjs(item.date_created).format("YYYY-MM-DD")}</Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Feature>
            <Feature on={total > per_page}>
                <Pagination page={page} total={total} limit={10} setPage={setPage} />
            </Feature>
        </>
    )
}


export const DistributionTable = ({ items, onItemDelete, onItemEdit }: any) => (
    <Table size="sm" variant="simple">
        <Thead>
            <Tr>
                <Th>Group</Th>
                <Th>Type</Th>
                <Th>Amount</Th>
                <Th />
            </Tr>
        </Thead>
        <Tbody>
            {items?.map((item: any) =>
                <Tr key={item.uuid}>
                    <Td>{item.uuid}</Td>
                    <Td>{item.strategy}</Td>
                    <Td>{item.amount.toLocaleString()}</Td>
                    <Td>
                        <div className="flex space-x-2">
                            {onItemEdit ? <IconButton
                                size="xs"
                                _hover={{ color: 'calypso.500' }}
                                variant="link"
                                aria-label="Edit item"
                                onClick={() => onItemEdit(item)}
                                icon={<EditIcon />} /> : null}
                            {onItemDelete ? <IconButton
                                size="xs"
                                _hover={{ color: 'red' }}
                                variant="link"
                                aria-label="Delete item"
                                onClick={() => onItemDelete(item)}
                                icon={<FaTrash />} /> : null}
                        </div>
                    </Td>
                </Tr>
            )}
        </Tbody>
    </Table>
);
