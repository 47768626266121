import React from 'react';
import { Switch } from 'react-router';
import PrivateRoute from '../private-route/private-route';
import { AppRoutes } from '../routes';
import ContributionsView from './contributions';


export const GroupRoutes = () => (
  <Switch>
    <PrivateRoute path={`${AppRoutes.GROUP_CONTRIBUTIONS}/:group_id`} exact component={ContributionsView} />
  </Switch>
);
