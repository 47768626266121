import { Badge, Box, Text, useBreakpointValue } from '@chakra-ui/react';
import { useSession } from 'app/hooks/backend/session';
import React from 'react';
import { capitalize } from "lodash";
import { Feature } from 'app/components/controls';


export const NavBar = () => {
  const { user } = useSession();
  const hidden = useBreakpointValue({ base: true, md: false });


  return (
    <nav className="flex items-start justify-between">
      <Box className="flex items-start space-x-2">
        <Text color="calypso.900" fontWeight="thin" fontSize={["xl", "2xl"]}>Hello, {capitalize(user?.first_name)}!</Text>
      </Box>
      <ul className="flex items-center flex-shrink-0 space-x-1">
        <Feature on={process.env.NODE_ENV !== 'production'}>
          <li className="flex">
            <Badge colorScheme="flow" >{process.env.NODE_ENV}</Badge>
          </li>
        </Feature>
        <li className="flex" hidden={!hidden}>
          <a className="text-black inline-flex items-center w-full px-1 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-[#F3F6F9] hover:text-gray-800"
            href="/">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="ml-1" hidden={hidden}>Profile</span>
          </a>
        </li>
        <li className="flex">
          <a className="text-black inline-flex items-center w-full px-1 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-[#F3F6F9] hover:text-gray-800"
            href="/">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            <span className="ml-1" hidden={hidden}>Log out</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

