import React from 'react';
import NumberFormat from 'react-number-format';
import { Input } from '@chakra-ui/react';

export const NumberInput = ({ ...props }) => {
    const handleInputChange = (e: any) => {
        const { value } = e.target;
        const parsedInput = Number(value.replace(/[^0-9.-]+/g, ''));
        props.onChange(parsedInput);
    };

    return (
        <NumberFormat
            customInput={Input}
            thousandSeparator={true}
            prefix={'UGX '}
            {...props}
            onChange={handleInputChange}
        />
    );
};