import * as React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Router from 'app/pages';
import brand from './brand';
import { QueryClient, QueryClientProvider } from 'react-query';

const theme = extendTheme(brand);
const queryClient = new QueryClient();

const App = () => {
   
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Router />
      </ChakraProvider>
    </QueryClientProvider>
  )
};

export default App;
